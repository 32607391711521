// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface Item {
  title: string;
  blogs: {
    id: number;
    bx_block_blogpostsmanagement_blogpost_categories_id: number;
    title: string;
    content: string;
    content_show: boolean;
  }[];
}
interface DataItem {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}



interface AttachmentUrl {
  url: string;
  type: string;
  size: number;
  attachment_id: number;
}

interface Blog {
  id: number;
  title: string;
  description: string;
  status: boolean;
  price: number | null;
  user_type: string | null;
  quantity: number | null;
  created_at: string;
  updated_at: string;
  category_id: number;
  author: string;
}

interface SlideShow {
  id: number;
  // id: number;
  title: string;
  description: string;
  status: boolean;
  price: number | null;
  user_type: string | null;
  quantity: number | null;
  created_at: string;
  updated_at: string;
  category_id: number;
  author: string;
  attachment_urls: AttachmentUrl[];
}

interface SearchAttachment {
  url: string;
  type: string;
  size: number;
  attachment_id: number;
}

interface SearchBlog {
  id: number;
  title: string;
  author: string;
  content: string | null;
  description: string;
  category_id: number;
  category_name: string;
  attachment_urls: SearchAttachment[];
}

interface Attachment {
  url: string;
  type: string;
  size: number;
  attachment_id: number;
};

interface BlogDetails  {
  id: number;
  title: string;
  author: string;
  content: string | null;
  description: string;
  category_id: number;
  category_name: string;
  attachment_urls: Attachment[];
};

const initialBlogState: BlogDetails = {
  id: 1,
  title: 'Titlr1',
  author: 'test user',
  content: null,
  description: 'What is Lorem Ipsum',
  category_id: 1,
  category_name: 'Cooking',
  attachment_urls: [],
};

// Customizable Area End

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  blogData: {
    title: string;
    blogs: {
      id: number;
      bx_block_blogpostsmanagement_blogpost_categories_id: number;
      title: string;
      content: string;
      content_show: boolean;
    }[];
  }[];
  blogData1: {
    title: string;
    blogs: {
      id: number;
      bx_block_blogpostsmanagement_blogpost_categories_id: number;
      title: string;
      content: string;
      content_show: boolean;
    }[];
  }[];
  isLoading: boolean;
  content: boolean;
  blogContent: string | null;
  searchText: string;
  isActive: boolean;
  selected: DataItem | string;
  options: string[];
  data: Item[]; 
  categoriesData: DataItem[];
  slide_shows: SlideShow[];
  getData: any;
  currentIndex: number;
  blogDetails: any;
  blogSearch: SearchBlog[];
  showBlogs:BlogDetails;
  authToken: any;
  // Customizable Area End
}

interface SS {
  id: string | number;
  // Customizable Area Start
  // Customizable Area End
}

export interface StoreInfo {
  data?: string;
  type?: string;
  name?: string;
  attribs: {
    src: string;
    height: string;
  };
}
export interface Types {
  item: {
    content: string;
    title:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  };
}
export interface AttributesInfo {
  // Customizable Area Start
  title: string;
  content: string;
  blogs: {
    map(
      arg0: (item: {
        content: string;
        title:
        | boolean
        | import("react").ReactChild
        | import("react").ReactFragment
        | import("react").ReactPortal
        | null
        | undefined;
      }) => JSX.Element
    ): string | number | boolean | {} | null | undefined;
  };

  // Customizable Area End
}

export default class BlogPostsManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBlogsApiCallId: Message | string = "";
  getAllCategories: any;
  getAllBlogs: any;
  getAllSearchApi: any;
  getSearchBarApi: any;
  showBlogContentApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      blogData: [],
      isLoading: false,
      content: false,
      blogContent: "",
      blogData1: [],
      searchText: "",
      isActive: false,
      selected: "All categories",
      options: ["One", "Two", "Three"],
      data: [],
      categoriesData:[],
      slide_shows: [],
      getData:"",
      currentIndex: 0,
      blogDetails: [],
      blogSearch: [],
      showBlogs:initialBlogState,
      authToken: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
       
        if(apiRequestCallId === this.getAllCategories){
          this.setState({categoriesData:responseJson})  
        }
        else if(apiRequestCallId === this.getAllBlogs){
          this.setState({blogDetails:responseJson.Blog_details});
          
          
        }
        else if(apiRequestCallId === this.getAllSearchApi){
          this.setState({blogDetails: responseJson.Blog_details});
        }

        else if(apiRequestCallId === this.getSearchBarApi){
          this.setState({blogDetails: responseJson.Blog_details})
          
        }
        else if(apiRequestCallId === this.showBlogContentApiCallId){
          this.setState({showBlogs: responseJson.Blog_details})
        }
        
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {   
      const receivedMessage = message.getData(getName(MessageEnum.SessionResponseData));
      this.setState({getData:receivedMessage.data});
      sessionStorage.setItem("id",receivedMessage.data)
      

      this.showBlogContentAPi(receivedMessage.data);
  }
  
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
  this.getAllBlogCategories();
  this.getAllBlogsDetails();
  if (sessionStorage.getItem("id")!=null)
  this.showBlogContentAPi(sessionStorage.getItem("id"));
  this.checkAuthToken();
  }

  goToDashbordPage = (item: string) => {
    this.props.navigation.navigate("BlogContent", { itemcontent: item });
  };

  getTheBlogs = () => {
    this.setState({
      isLoading: true,
    });

    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const getPopularCourse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBlogsApiCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.BlogpostEndPoint
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
    return true;
  };
  setData = (data: string) => {
    this.setState({
      blogContent: data,
    });
  };

  handleClear = () => {
    this.setState({ searchText: "" });
    this.getAllBlogsDetails();
  };
  
  handleOptionClick = (option: DataItem) => {
    this.setState({ selected: option.name, isActive: false });
  };

  gotoDestinationScreen = (data:any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BlogpostsDetails"
    );
     
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: data.id });
    
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    
   
    this.send(message);
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }
  

  getAllBlogCategories = () => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCategories = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllcategoriesEndPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllBlogsDetails = () => {
    let token = localStorage.getItem("token");

    
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
      // token: "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTkxLCJleHAiOjE3MTc4Mjg0Njl9.hGqxGKLsDSR_nfjIB5wEIRUQADCNmfpR4g7de7Hq6ezahbfuE9CQFsolKci-w-BfuxNHdmaTHx_sMZ2OExB0pg"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllBlogs = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllPostsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSearchAllCategories = (option_id:number, option_name:any) => {
    
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
      // token: "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTkxLCJleHAiOjE3MTc4Mjg0Njl9.hGqxGKLsDSR_nfjIB5wEIRUQADCNmfpR4g7de7Hq6ezahbfuE9CQFsolKci-w-BfuxNHdmaTHx_sMZ2OExB0pg"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSearchApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSearchEndPoint+"?id=" +option_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  };


  
  handleLeftClick = () => {
    this.setState((prevState) => ({
      currentIndex: Math.max(prevState.currentIndex - 3, 0) // Move to previous set of cards
    }));
  };

  handleRightClick = () => {
    this.setState((prevState) => ({
      currentIndex: Math.min(prevState.currentIndex + 3, this.state.blogDetails.length - 3) // Move to next set of cards
    }));
  };
   


  handleToggle() {
    this.setState({ isActive: !this.state.isActive });
  }
  
  handleChange(e:any) {
    this.setState({ searchText: e.target.value });
    this.getSearchBarData(e.target.value);
  }
  handleSubmit = (event: any) => {
    event.preventDefault();
}

onLoginClick = () => {
  this.props.navigation.navigate("Login")
}

onRegisterClick = () => {
  this.props.navigation.navigate("Signuplogin")
}

handleOptionSelect(option:any) {
  if(option.id === "all"){
    this.getAllBlogsDetails();
    this.setState({ selected: option.name, isActive: false });
  }
  else{
  this.setState({ selected: option.name, isActive: false });
  this.getSearchAllCategories(option.id, option.name);
  }
}

handleBackClick = () => {
  this.props.navigation.navigate('BlogpostsManagement');
};



profileClickButton = () => {
  this.props.navigation.navigate("CustomisableEditprofile");
}

subscriptionClickBtn = () => {
  this.props.navigation.navigate("Subscription");
}

paymentClickBotton = () => {
  this.props.navigation.navigate("PaymentSettings");
}


handleLogin = (): string | null => {
  return localStorage.getItem("token");
}


getSearchBarData = (query_id:any) => {
    
  let token = localStorage.getItem("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getSearchBarApi = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getSearchBarEndPoint+"?query=" + query_id
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

checkAuthToken = () => {
  const checkToken = localStorage.getItem("token");
  if (checkToken) {
    this.setState({ authToken: true });
  }
}

getBackgroundColor = (optionName:any) => {
  if (this.state.selected === optionName) {
    return "lightGray";
  }
};


showBlogContentAPi = (categoryId:any) => {
  
    
  let token = localStorage.getItem("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.showBlogContentApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getShowBlogContentEndPoint+"?id=" + categoryId
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};



  // Customizable Area End
}
// Customizable Area End
