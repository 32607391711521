import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled, Card, CardContent } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
// Customizable Area End
import MomentsPageController, {
    Props,
} from "./MomentsPageController";
import { mainMomments, recipeDesign1, recipeBannerEmpty, recipeDesign2, recipeDesign3, recipeDesign4, recipeDesign5, recipeFeatureBg, downArrow, recipeDesign8, recipeDesign10, outlineCheck, familyTreeCircle, familyTreePin } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";

export default class MomentsPage extends MomentsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderBackground() {
        const { landingData } = this.state;
        const backgroundStyle = {
            backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${landingData[0]?.background_image_url})`,
            width: '100%',
            backgroundPosition: 'center',
            maxWidth: '1635px',
            height: '850px',
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'end',
        };
    
        return (
            <Box style={backgroundStyle}>
                <Box style={{ width: "100%", maxWidth: "666px",marginRight: 50 }}>
                    <Typography style={webStyles.HeaderTextMoment}>{landingData[0]?.title}</Typography>
                    <Typography style={webStyles.subHeaderMoment}>
                        {landingData[0]?.description}
                    </Typography>
                    <Button type="submit" style={webStyles.continueButtonMoment}>
                        <Typography style={webStyles.continueTextMoment as any}>Get started</Typography>
                    </Button>
                </Box>
            </Box>
        );
    }

    renderFaqs() {
        const { landingData } = this.state;
        return (
            <Container maxWidth="md" style={{ marginBottom: 40, marginTop: 20 }}>
                <div style={webStyles.faqContainer}>
                    {landingData.length > 0 && landingData[0]?.landing_page_faqs?.length > 0 ? (
                        landingData[0].landing_page_faqs.map((faq: any, index: any) => (
                            <Box
                                key={faq.id}
                                style={{
                                    marginBottom: '16px',
                                    borderRadius: '4px',
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '12px',
                                        cursor: 'pointer',
                                        borderBottom: this.state.recipeFaq === index ? 'none' : '1px solid #D6D3D1',
                                    }}
                                    onClick={() => this.handleAccordian(index)}
                                    data-test-id={`coveracordian-${index + 1}`}
                                >
                                    <Typography style={{ flex: 1, fontSize: 18, fontFamily: 'Poppins', fontWeight: 700 }}>
                                        {faq.question}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        aria-expanded={this.state.recipeFaq === index}
                                        style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                                        aria-label="show more"
                                    >
                                        <img
                                            src={downArrow}
                                            style={{
                                                transform: this.state.recipeFaq === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                                width: 24,
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                                {this.state.recipeFaq === index && (
                                    <Box style={{ padding: '12px', borderBottom: '1px solid #D6D3D1' }}>
                                        <Typography
                                            style={webStyles.faqStyleMoment}
                                        >
                                            {faq.answer}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        ))
                    ) : (
                        <Typography>No FAQs available</Typography>
                    )}
                </div>
            </Container>
        );
    }


    renderWorkStorages() {
        const { landingData } = this.state;
        return (
            <Box style={{ width: "100%", maxWidth: "1250px", marginTop: 70 }}>
                {landingData && landingData[0]?.work_storages?.length > 0 ? (
                    landingData[0].work_storages.map((storage: any, index: any) => (
                        <Box key={index} style={{ display: 'flex', position: 'relative', marginTop: index === 0 ? 0 : 70 }}>
                            {index < 1 || (
                            <img
                                    src={familyTreeCircle}
                                    style={{
                                        width: "100%",
                                        maxWidth: "204px",
                                        position: 'absolute',
                                        right: 518,
                                        top: -120,
                                        height: 230
                                    }} />
                            )}
                            <Box style={{ display: 'flex', justifyContent: 'center', marginTop:50 }}>
                                {index % 2 === 0 ? (
                                    <>
                                        <img src={recipeBannerEmpty} style={{ maxWidth: "620px", height:490, zIndex:1 }} />
                                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 50 }}>
                                            <Typography style={webStyles.familyText as React.CSSProperties}>
                                                {storage.title}
                                            </Typography>
                                            <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "#1C2110" }}>
                                                {storage.description}
                                            </Typography>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 50 }}>
                                            <Typography style={webStyles.familyText as React.CSSProperties}>
                                                {storage.title}
                                            </Typography>
                                            <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "#1C2110", textAlign: "right" }}>
                                                {storage.description}
                                            </Typography>
                                        </Box>
                                        <img src={recipeBannerEmpty} style={{ maxWidth: "620px", height:490, zIndex:1 }} />
                                    </>
                                )}
                            </Box>
                        </Box>
                    ))
                ) : (
                    <Typography>No work storages available.</Typography>
                )}
            </Box>
        );
    }
    
    

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { landingData } = this.state;
        return (
            //Merge Engine DefaultContainer
            <Box>
                <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick}
                    getHeaderData={(data) => {
                        this.landingPageFilterAPi(data);
                    }}
                />
                 {this.renderBackground()}
              
                <Box>
                    <Box style={{ display: "flex", justifyContent: 'center', gap: 80, marginTop: 60, position: 'relative' }}>

                        <img src={recipeDesign5} style={{ maxWidth: "297px", width: "100%", position: 'absolute', right: 194, top: -200 }} />
                        <img src={recipeDesign1} style={{ maxWidth: "220px", width: "100%", position: 'absolute', left: 0, top: -130, zIndex: -1 }} />
                        <img src={recipeDesign3} style={{ maxWidth: "150px", width: "100%", position: 'absolute', right: 0, top: -650, height: "356px" }} />

                        <Card style={{ width: "100%", zIndex: 2, maxWidth: "1133px", borderRadius: 16, backgroundColor: "#fff", color: "#fff",
                        paddingTop: 0,
                        paddingRight: 30,
                        paddingBottom: 30,
                        paddingLeft: 30, boxShadow: "0 4px 8px rgba(0.5, 0.5, 0.5, 0.5)" }}>
                            <CardContent>
                                <Typography style={webStyles.contentMomentTitle as any}>
                                    {landingData[0]?.content_title}
                                </Typography>
                                <Typography style={webStyles.contentMomentDescription}>
                                    {landingData[0]?.content_description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>



                <MomentPageStyle style={{ display: 'flex', justifyContent: 'center', marginTop: 60, gap: "50px" }}>
                    {landingData?.[0]?.benefits?.map((benefit: any, index: any) => (
                        <Box className="priceBoxMoment" key={benefit.id}>
                            <Typography className="priceTitleMoment">{benefit.title}</Typography>
                            <Box className="priceCenterDivMoment">
                                <Typography className="priceSubTitleMoment" dangerouslySetInnerHTML={{ __html: benefit.description }} />
                            </Box>
                            <Button className="startBtnMoment" onClick={this.startNowBtn}>Start now</Button>
                        </Box>
                    )) || null}
                </MomentPageStyle>
                <Box style={{ display: 'flex', marginTop: 100, position: "relative", justifyContent: 'center', }}>
                    <Box style={webStyles.backgroundImageFeature as any}>
                        <img src={recipeDesign8} style={{ width: "100%", position: 'absolute', maxWidth: "229px", right: 0, top: -167, height: 300 }} />
                        <img src={recipeDesign2} style={{ width: "100%", position: 'absolute', maxWidth: "130px", left: 0, top: 90, height: 150 }} />
                        <img src={recipeDesign10} style={{ width: "100%", maxWidth: "180px", position: 'absolute', top: 1000, right: 0, height: 352 }} />
                        <img src={familyTreePin} style={{ width: "100%", maxWidth: "390px", position: 'absolute', left: 0, bottom: 50 }} />
                        <Typography style={webStyles.bestFeatures}>How to Save Family Moments with MyKinship</Typography>
                        <Typography style={webStyles.bestFeatures2 as any}>MyKinship offers a seamless and secure way to manage your family’s digital photo collection. Here’s how it works</Typography>

                       {this.renderWorkStorages()}



                    </Box>

                </Box>
                <Box style={{ maxWidth: 1240, margin: 'auto', width: '100%' }}>
                    <Typography style={webStyles.frequentlyText}>Frequently asked questions</Typography>
                    <Typography style={webStyles.simpleText2}>Simple, transparent pricing that grows with you. Try any plan free for 30 days.</Typography>
                </Box>
                {this.renderFaqs()}


                <Box style={{ position: 'relative' }}>
                    <Box style={{ width: '100%', maxWidth: 1240, margin: 'auto' }}>
                        <img src={recipeDesign4} style={{ objectFit: 'contain', position: 'absolute', right: 0, height: 420, top: -80, zIndex: -1 }} />
                        <Typography style={webStyles.moreQuestions}>Reach Out to Us with Any Questions</Typography>
                        <Typography style={webStyles.moreQuestions2}>Losing your mementos, primarily pictures or videos, might be irreversible, so why not store them in a safe location? If you want to immortalize your memories and still have access to them anytime from anywhere, sign up for MyKinship.</Typography>
                    </Box>
                </Box>
                <Footer navigation={this.props.navigation} id={""} />
            </Box>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    container: {
        display: "grid",
        gridTemplateColumns: "3fr 7fr",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        width: "32rem",
        padding: "0.5rem 1rem",
        minHeight: "12rem",
        gap: "2rem",
    },
    HeaderTextMoment: {
        fontSize: 72,
        fontWeight: 400,
        fontFamily: 'Abygaer',
        color: '#305328',
        marginTop: 130,
        width: "100%",
        maxWidth: "700px"
    },
    subHeaderMoment: {
        color: '#305328',
        fontWeight: 400,
        fontSize: 24,
        fontFamily: "Poppins"
    },
    backgroundImage: {
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${mainMomments})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        maxWidth: "1635px",
        height: '800px',
        display: 'flex',
        justifyContent: "end",
    },
    continueButtonMoment: {
        backgroundColor: "#8CA74B",
        borderRadius: '8px',
        height: '56px',
        width: '292px',
        marginTop: '80px',
    },
    continueTextMoment: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: 'none'
    },
    familyText: {
        color: "#1C1917",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: 36
    },
    textContainer: {
        width: "100%",
        maxWidth: "656px"
    },
    familyText2: {
        color: "#305328",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: 48,
        textAlign: 'right',
        width: "100%",
        maxWidth: "720px"
    },
    contentText: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 20,
        color: "#1C2110"
    },
    bestFeatures: {
        color: "#305328",
        fontWeight: 700,
        fontSize: 48,
        fontFamily: "Poppins",
        marginTop: 30
    },

    bestFeatures2: {
        color: "#5D6063",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: 20
    },
    backgroundImageFeature: {
        backgroundImage: `url(${recipeFeatureBg})`,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '2230px',
        display: 'flex',
        backgroundSize: 'cover',
        flexDirection: "column",
        alignItems: 'center',
    },
    frequentlyText: {
        fontSize: 36,
        fontFamily: 'Poppins',
        fontWeight: 700,
        color: '#1C1917',
        marginTop: 50
    },
    simpleText2: {
        fontSize: 18,
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: '#5D6063'
    },

    moreQuestions: {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: 36,
        color: "#1C1917"
    },
    moreQuestions2: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 18,
        color: "#5D6063",
        marginBottom: 70
    },
    contentMomentTitle:
    {
        fontWeight: 700, fontFamily: "Poppins", fontSize: 48, color: "#305328", textAlign: "center"
    },
    contentMomentDescription: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 20,
        color: "1C2110",
        marginTop:20
    },
    faqContainer:{
    width: '100%',
    maxWidth: 920, 
    marginTop: 50, 
    margin: 'auto' 
},
faqStyleMoment:{
    flex: 1,
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 16,
    color: '#5D6063',
}
};

const MomentPageStyle = styled("div")({

    "& .priceMainBoxMoment": {
        display: 'flex',
        justifyContent: 'space-between',
        width: '94%',
    },
    "& .priceBoxMoment": {
        width: '370',
        height: '398',
        borderRadius: '16px',
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
    },
    "& .priceTitleMoment": {
        lineHeight: "44px",
        fontWeight: 700,
        fontSize: "36px",
        fontFamily: 'Poppins',
        color: '#1C2110',
        letterSpacing: '-1%',
        marginBottom: '5px',
        marginTop: '40px',
        textAlign: 'center'
    },
    "& .priceSubTitleMoment": {
        lineHeight: "26px",
        fontWeight: 400,
        fontSize: "18px",
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '20px',
        textAlign: 'center'
    },
    "& .priceCenterDivMoment": {
        backgroundColor: "#E8EDDB",
        textAlign: 'center',
        display: "flex",
        alignItems: "center",
        marginTop: 40
    },

    "& .priceCenterBoxMoment": {
        backgroundColor: "#557C29",
        height: '140px'
    },
    "& .priceTagMoment": {
        lineHeight: "56px",
        fontWeight: 500,
        fontSize: "64px",
        color: '#FFFFFF',
        textAlign: 'center',
        fontFamily: 'Inter',
        letterSpacing: '-1.5%',
        padding: '13px',
    },
    "& .dollarMoment": {
        lineHeight: "56px",
        fontWeight: 300,
        fontSize: "32px",
        color: '#FFFFFF',
        fontFamily: 'Inter',
        letterSpacing: '-1.5%',
    },
    "& .monthTextMoment": {
        lineHeight: "28px",
        fontSize: "20px",
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: '#FFFFFF',
        marginBottom: '20px',
        textAlign: 'center',
        display: "block",
        alignItems: "center",
    },
    "& .storageTextMoment": {
        lineHeight: "28px",
        fontWeight: 400,
        fontSize: "20px",
        color: '#1C2110',
        marginBottom: '40px',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: '20px',
    },
    "& .startBtnMoment": {
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        padding: '10px',
        marginBottom: '48px',
        marginLeft: "auto",
        marginTop: '40px',
        marginRight: "auto",
        textTransform:"none",
        display: "block",
        width: '182px',
        "& .Muibutton-label": {
            fontWeight: 700,
            fontSize: "16px",
            fontFamily: 'Poppins',
            lineHeight: "24px",
            color: '#FFFFFF',
            textAlign: 'center',
        }
    }
})


// Customizable Area End
