import React from 'react';
// Customizable Area Start
import {
    Button,
    styled,
    Box,
    IconButton,
    TextField,
    InputAdornment,
    Snackbar,
} from '@material-ui/core';
import FooterController, {
    Props,
} from "./FooterController";
import { Typography } from 'antd';
import { XIcon, FbIcon, groupLogo, InstagramIcon, YoutubeIcon } from '../../signuplogin/src/assets'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Alert } from '@material-ui/lab';
// Customizable Area End

export default class Footer extends FooterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <FooterStyle>
                <Box className='mainDiv'>
                    <Box className="mainContainer">
                        <div className="scrollableContent">
                            <Box className='columnContainer'>
                                <Box className='logoContainer'>
                                    <img className='imgLogo' src={groupLogo} alt="Logo" />
                                </Box>
                                <Box className='screenContainer'>
                                    <Typography className='titleText'>MY KINSHIPS</Typography>
                                    <Button className='menuTitle' onClick={this.handleHomeClick}>Home</Button>
                                    <Button className='menuTitle' onClick={this.handlePhotosClick}>Photos</Button>
                                    <Button className='menuTitle' onClick={this.handleMomentsClick}>Moments</Button>
                                    <Button className='menuTitle' onClick={this.handleFamilyClick}>Family Tree</Button>
                                    <Button className='menuTitle' onClick={this.handleRecipesClick}>Recipes</Button>
                                    <Button className='menuTitle' onClick={this.handlePlansClick}>Plans and pricing</Button>
                                </Box>
                                <Box className='connectContainer'>
                                    <Typography className='titleText'>CONNECT</Typography>
                                    <Button className='menuTitle' onClick={this.handleBlogClick}>Blog</Button>
                                    <Button className='menuTitle' onClick={this.handleContactClick}>Contact us</Button>
                                    <Button className='menuTitle' onClick={this.handleNewsLetterClick}>Newsletter</Button>
                                </Box>
                                <Box className='subscribeContainer'>
                                    <Typography className='titleText'>SUBSCRIBE</Typography>
                                    <Typography className='subTitle'>Enter your e-mail to get notified about our newsletter.</Typography>
                                    <TextField
                                        type='email'
                                        className='emailField'
                                        placeholder='Your e-mail'
                                        value={this.state.email}
                                        onChange={this.handleEmailChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment style={{ color: 'white' }} position="end">
                                                    <IconButton edge="end" onClick={this.emailClick}>
                                                        <MailOutlineIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={!!this.state.error}
                                        helperText={this.state.error}
                                    />
                                    {this.state.success &&
                                        <Snackbar autoHideDuration={3000} >
                                            <Alert severity="success" style={{ backgroundColor: '#F8FFE7', color: '#1C1917', fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px', display: 'flex', alignItems: 'center' }}>{this.state.success}</Alert>
                                        </Snackbar>
                                    }
                                </Box>
                                <Box className='contactDetails'>
                                    <Typography className='contactDescription'>123 Memory Lane, Digital City,
                                        <br />
                                        DC 56789, United States
                                    </Typography><br />
                                    <Typography className='contactDescription'>+1 (555) 123-4567</Typography><br />
                                    <Typography className='contactDescription'>info@mykindships.com</Typography>
                                </Box>
                            </Box>

                            <div className="horizontalLine"></div>
                            <Box className='bottomContainer'>
                                <Box className='bottomRow'>
                                    <Button className='policyText' onClick={this.handlePolicyClick}>Privacy Policy and Terms and Conditions</Button>
                                    <Button className='policyText' onClick={this.handleSitemapClick}>Sitemap</Button>
                                </Box>
                                <Box className='rightsDiv'>
                                    <Typography className='rightsText'> &copy; 2024 My Kinships. All rights reserved.</Typography>
                                    <Box className='socialIcons'>
                                        <Button><img src={XIcon} /></Button>
                                        <Button><img src={FbIcon} /></Button>
                                        <Button><img src={InstagramIcon} /></Button>
                                        <Button><img src={YoutubeIcon} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    </Box>
                </Box>
            </FooterStyle>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const FooterStyle = styled("div")({
    "& .mainDiv": {
        backgroundColor: "#1C2110",
    },
    "& .mainContainer": {
        color: 'white',
        fontWeight: 400,
        textTransform: "none",
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "22px",
        padding: "35px",
        marginLeft: '60px',
        marginRight: '60px',
        '@media (max-width: 1400px)': {
            marginLeft: '20px',
            marginRight: '20px',
        },
        '@media (max-width: 769px)': {
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    "& .scrollableContent": {
        overflowX: "auto",
        overflowY: "hidden",
        display: "flex",
        flexDirection: 'column',
    },
    "& .columnContainer": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .screenContainer": {
        display: "flex",
        flexDirection: 'column',
    },
    "& .connectContainer": {
        display: "flex",
        flexDirection: 'column'
    },
    "& .titleText": {
        color: 'white !important',
        fontWeight: "700 !important",
        textTransform: "none",
        fontFamily: "Poppins",
        fontSize: "16px !important",
        lineHeight: "24px !important",
        marginBottom: '16px',
        '@media (max-width: 1400px)': {
            fontWeight: "700 !important",
            fontSize: "14px !important",
        },
        '@media (max-width: 800px)': {
            fontWeight: "700 !important",
            fontSize: "12px !important",
        },
    },
    "& .emailField": {
        color: 'white',
        marginTop: '16px',
        "& .MuiInputBase-input": {
            color: 'white',
        },
        "& .MuiInputBase-root": {
            border: "2px solid white",
            borderRadius: '8px',
            height: '44px',
            padding: "10px",
            gap: '8px'
        },
        "& .MuiIconButton-root": {
            color: 'white',
        },
        '@media (max-width: 1400px)': {
            width: '256px'
        },
        '@media (max-width: 800px)': {
            width: '236px'
        },
    },
    "& .horizontalLine": {
        borderBottom: '1px solid white',
        borderRadius: '0.5px',
        marginTop: '24px'
    },
    "& .subTitle": {
        color: 'white !important',
        fontWeight: 400,
        textTransform: "none",
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "22px",
        '@media (max-width: 1400px)': {
            fontSize: "14px",
        },
        '@media (max-width: 800px)': {
            fontSize: "12px",
        },
    },
    "& .subscribeContainer": {
        display: "flex",
        flexDirection: 'column',
        '@media (max-width: 1400px)': {
            marginRight: '5px',
            marginLeft: '5px'
        },
        '@media (max-width: 800px)': {
            marginRight: '5px',
            marginLeft: '5px'
        },
    },
    "& .contactDescription": {
        color: 'white',
        fontWeight: 400,
        textTransform: "none",
        fontFamily: "Poppins",
        fontSize: "12px",
        lineHeight: "18px",
    },
    "& .menuTitle": {
        justifyContent: 'start',
        color: 'white',
        fontWeight: 400,
        textTransform: "none",
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        '@media (max-width: 1400px)': {
            fontSize: "16px",
        },
        '@media (max-width: 800px)': {
            fontSize: "12px",
        },
    },
    "& .bottomContainer": {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: 'space-between',
        marginTop: '20px'
    },
    "& .bottomRow": {
        display: "flex",
        gap: "20px",
    },
    "& .policyText": {
        color: 'white',
        fontWeight: 400,
        textTransform: "none",
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "22px",
        '@media (max-width: 1400px)': {
            fontSize: "14px",
        },
        '@media (max-width: 800px)': {
            fontSize: "12px",
        },
    },
    "& .rightsDiv": {
        display: 'flex',
        alignItems: "center",
        textAlign: "center",
    },
    "& .rightsText": {
        color: 'white',
        fontWeight: 400,
        textTransform: "none",
        fontFamily: "Poppins",
        fontSize: "12px",
        lineHeight: "18px",
        marginRight: '20px'
    }
});
// Customizable Area End